// @flow
import initState from '../initState';

const UPDATE_RESAMPLE = 'UPDATE_RESAMPLE';

// REDUCER
export default function worksReducer(state = initState.resample, action) {
  switch (action.type) {
    case UPDATE_RESAMPLE:
      return Object.assign(
        {},
        state,
        {foobar: action.data}
      );
    default:
      return state;
  }
}

// ACTIONS
export class WorksStateActions {
  static updateResample = data => ({
    type: UPDATE_RESAMPLE,
    data,
  });
}
