import React from "react";
import { Provider } from "react-redux";

import configureStore from "./src/state/store/store";

export const wrapRootElement = ({ element }) => {
  const store = configureStore().default();

  const ConnectedRootElement = <Provider store={store}>{element}</Provider>;

  return ConnectedRootElement;
};
